import React from 'react';
import { Badge, Tooltip, TextStyle, Icon, List, Link, Button, ButtonGroup } from '@shopify/polaris';
import PopupItems from '@shared/PopupItems';
import { NoteMajorMonotone, RiskMajorMonotone } from '@shopify/polaris-icons';

import PolarisBooleanBadge from '@components/shared/ui/PolarisBooleanBadge';

import { statusVariations as orderStatusVariations } from './index';

import { ta } from './index';

import snakeCase from 'lodash/snakeCase';

const RoleWithPosition = ({ role, position, color }) => (
  <p>
    <Badge status={color}>{position}</Badge>
  </p>
)

// 'attention' | 'info' | 'new' | 'success' | 'warning
export const valueToBadgeStatus = {
  // Task/Order statuses
  assigned: "info",
  in_progress: "info",
  finished: "success",
  failed: "warning",
  cancelled: "warning",
  unassigned: "attention",

  // ResourceTransfer statuses
  pending: "attention",
  accepted: "success",
  rejected: "warning",

  // CustomerTicket
  NEW_TICKET: 'new',
  ESCALATED: "attention",
  REJECTED: "warning",
  APPROVED: "info",
  APPROVED_AWAITING_ITEMS: "info",
  WAITING_RESOLUTION: "info",
  PROCESSING_RESOLUTION: "info",
  APPROVED_AND_COMPLETED: "success",
}

// 'incomplete' | 'partiallyComplete' | 'complete'
export const valueToBadgeProgress = {
  unassigned: "incomplete",
  cancelled: "incomplete",
  assigned: "partiallyComplete",
  in_progress: "complete",
  finished: "complete",
  failed: "incomplete",

  // CustomerTickets
  NEW_TICKET: "incomplete",
  ESCALATED: "incomplete",
  REJECTED: "complete",
  APPROVED: "partiallyComplete",
  APPROVED_AWAITING_ITEMS: "partiallyComplete",
  WAITING_RESOLUTION: "partiallyComplete",
  PROCESSING_RESOLUTION: "partiallyComplete",
  APPROVED_AND_COMPLETED: "complete",
}

/**
 * Useful function to display the data of a list of records based on the objects list and how to display them.
 * @param { object } record - The record to get the display value from
 * @param { object } columnInfo  - The column info to know how to display this "cell" (must have AT least accessor property)
 * @param { object } utils - Utils attributes or functions passed to the cell builder when accessor is a function
 * @returns return a value or component depending on the columnInfo properties
 */
export const renderAccessorValue = (record, columnInfo, utils = null) => {
  const { accessor, attribute } = columnInfo; // Normal render using accessor/attribute provided
  if (typeof (accessor) == "string" || attribute) {
    return attribute ? record[attribute] : record[accessor]; // record['attribute']
  } else {
    return accessor(record, utils); // accessor as function like (record) => fullName(record)
  }
}

export const orderColumns = [
  { size: 1, header: "Número", accessor: "delivery_detail_provider_order_id" },
  { size: 1, header: "Ref", accessor: "order_number" },
  { size: 2, header: ta("general.customer"), accessor: (order) => (
    <div className='flex alignItemsCenter'>
      <span>
        { order?.provider_data?.shipping_address?.name }
      </span>
      {!!order.full_shipping_address &&
        <div className='tooltip-height-20 ml4' style={{ height: 16 }}>
          <Tooltip content={order.full_shipping_address}>
            <Icon source={NoteMajorMonotone} />
          </Tooltip>
        </div>
      }
    </div>
  ) },
  { size: 1, header: ta("order.status"), accessor: (order) => (
    <TextStyle variation={ orderStatusVariations[order.status] }>
      {I18n.t(`enum.status.${order.status}`)}
    </TextStyle>
  )},
  { size: 2, header: I18n.t("activerecord.models.courier.one"), accessor: (order) => (
    <div>
      <p>{order.courier_name}</p>
      <TextStyle variation='subdued'>{order.team_name}</TextStyle>
    </div>
  ) },
  { size: 1, header: "ETA (Iniciando Ruta)", accessor: (order) => order?.delivery_estimated_original_routing_started ? I18n.l("time.formats.eta_with_day", order.delivery_estimated_original_routing_started) : "" }, // First routing started ETA (from delivery detail)
  { size: 1, header: "Entrega", sortable_key: "delivered_at", accessor: (order) => order.delivered_at ? I18n.l("time.formats.eta_with_day", order.delivered_at) : "" },
  { size: 1, header: "Delta (ETA / Entrega)", accessor: (order) => {
    const textClass = order.eta_delta_is_negative ? "error" : "";
    return(
      <span className={textClass}>
        { order?.eta_delta_formatted }
      </span>
    )
  }},
  { size: 2, headers: "", accessor: (order, accessorUtils) => {
    const orderUrl = !!accessorUtils.baseUrl ? accessorUtils.baseUrl.replace(":id", order.id) : null;
    return( // Extracted from old OrderItem component
      <ButtonGroup>
        { orderUrl && <Button size="slim" primary external url={orderUrl}>Detalles</Button> }
        { !!accessorUtils.onUnassign && order.status == "assigned" && <Button size="slim" onClick={() => accessorUtils.onUnassign(order)} destructive>Desasignar</Button> }
      </ButtonGroup>
    )
  }},
]

export const teamColumns = [
  { size: 2, header: ta("team.name"), accessor: "name" },
  { size: 2, header: ta("team.user"), accessor: (team) => team.user.name },
  { size: 1, header: ta("team.disabled"), accessor: (team) =>  
    <div>
      <Badge status={ team.disabled ? "info" : "warning" }>
        { team.disabled ? I18n.t("true") : I18n.t("false") }
      </Badge>
    </div>
  },
  { size: 1, header: "Uber", accessor: (team) => ( 
      <div>
        <Badge status={ team.uber ? "info" : "warning" }>
          { team.uber ? I18n.t("true") : I18n.t("false") }
        </Badge>
      </div>
    )
  },
  { size: 1, header: "Pick up", accessor: (team) => ( 
      <div>
        <Badge status={ team.pickup ? "info" : "warning" }>
          { team.pickup ? I18n.t("true") : I18n.t("false") }
        </Badge>
      </div>
    )
  },
  { size: 2, header: ta("team.only_dispatch"), accessor: (team) => (
      <div>
        <Badge status={ team.only_dispatch ? "info" : "warning" }>
          { team.only_dispatch ? I18n.t("true") : I18n.t("false") }
        </Badge>
      </div>
    )
  },
]

export const taskColumns = [
  // # of task
  {
    size: 1, header: ta("task.name"), className: "flex-order-1-mobile", accessor: ({ name, notes, show_url }) => (
      <div className="flex align-items-center">
        <TextStyle>{name}</TextStyle>
        {!!notes &&
          <div className='tooltip-height-20 ml8' style={{ height: 16 }}>
            <Tooltip content={notes}>
              <Icon source={NoteMajorMonotone} />
            </Tooltip>
          </div>
        }
      </div>
    )
  },

  { size: 2, header: ta("task.contact"), className: "flex-order-3-mobile", accessor: ({ brightpearl_data }) => brightpearl_data.name },

  {
    size: 1, header: "Productos", className: "flex-order-3-mobile", accessor: ({ brightpearl_data, customer_task }) => brightpearl_data && brightpearl_data.order_items && (
      <div>
        <div className='hidden-mobile'>
          <PopupItems items={brightpearl_data.order_items} />
        </div>
        <div className='hidden-desktop'>
          <p>{brightpearl_data.order_items.length} {`producto${(brightpearl_data.order_items.length > 1) ? 's' : ''}`}</p>
        </div>
      </div>
    )
  },

  // order 2 on mobile!
  {
    size: 1, header: ta("general.status"), className: "flex-order-2-mobile", accessor: ({ status }) =>
      <Badge progress={valueToBadgeProgress[status]} status={valueToBadgeStatus[status]} >
        {I18n.t(`enum.status.${status}`)}
      </Badge>
  },

  // Absolute on mobile
  { size: 1, header: ta("task.kind"), className: "flex-order-2-mobile task-kind", accessor: ({ kind }) => I18n.t(`enum.kind.${kind}`) },

  { size: 1, header: ta("general.team"), className: "flex-order-3-mobile", accessor: ({ team }) => team && team.name },
  { size: 1, header: ta("general.courier"), className: "flex-order-3-mobile", accessor: ({ courier }) => courier && courier.name },
  { size: 1, header: ta("general.created_at"), className: "flex-order-3-mobile", accessor: ({ created_at }) => I18n.l("time.formats.date_only", created_at) },
  { size: 1, header: ta("task.estimated_at"), className: "flex-order-3-mobile", accessor: ({ estimated_at }) => estimated_at && I18n.l("time.formats.date_only", estimated_at) },
  { size: 1, header: ta("task.created_by"), className: "flex-order-3-mobile", accessor: ({ created_by }) => created_by && created_by.name },
  // { size: 2, header: ta("general.notes"), accessor: "notes" },

  // =============> !One column is free for ResourceItem shortcutActions! <===========
];

// For orders type
export const resourceTransferColumns = [
  {
    size: 1, header: ta("general.type"), accessor: ({ resource_type, resource }) => {
      if (resource_type == "Order") {
        return I18n.t(`enum.polymorphic_type.${resource_type}`)
      }
      else { // task
        return (
          // <div className='flex alignItemsCenter'>
          //   { I18n.t(`enum.polymorphic_type.${resource_type}`) }
          //   <div className='tooltip-height-20 ml8' style={{ height: 16 }}>
          //     <Tooltip content={ I18n.t(`enum.kind.${resource.kind}`) }>
          //       <Icon source={NoteMajorMonotone} />
          //     </Tooltip>
          //   </div>
          // </div>
          <div className='flex flexColumn'>
            <span>{I18n.t(`enum.polymorphic_type.${resource_type}`)}</span>
            <TextStyle variation='subdued'>{I18n.t(`enum.kind.${resource.kind}`)}</TextStyle>
          </div>
        )
      }
    }
  },
  { size: 1, header: ta("resource_transfer.transfer_type"), accessor: ({ transfer_type }) => I18n.t(`enum.transfer_type.${transfer_type}`) },
  { size: 1, header: ta("general.id"), accessor: (transfer) => transfer.resource_type == "Order" ? transfer.resource.provider_id : transfer.resource.name }, // GON ID!
  // { size: 1, header: ta("dispatch_order.name"), accessor: "name" }, // GON "name" => "GN8888/01" (order number and gon stuff)
  { size: 1, header: ta("dispatch_order.order_number"), accessor: ({ resource }) => resource.order_number },

  
  { 
    size: 1, header: "Productos", className: "flex-order-3-mobile", accessor: ( item ) =>  item.resource.brightpearl_data && item.resource.brightpearl_data.order_items  &&(
        <div>
          <div className='hidden-mobile'>
            <PopupItems items={item.resource.brightpearl_data.order_items} />
          </div>
          <div className='hidden-desktop'>
            <p>{item.resource.brightpearl_data.order_items.length} {`producto${(item.resource.brightpearl_data.order_items.length > 1) ? 's' : ''}`}</p>
          </div>
      </div>
      
      )
  },
  
  {
    size: 2, header: ta("resource_transfer.requested_by"), accessor: ({ requested_by, team_name }) => (
      <div className='flex flexColumn'>
        <span>{requested_by.name}</span>
        <TextStyle variation='subdued'>{team_name}</TextStyle>
      </div>
    )
  },
  { size: 2, header: ta("resource_transfer.created_at"), accessor: ({ created_at }) => I18n.l("time.formats.date_only", created_at) },
  {
    size: 1, header: ta("general.status"), accessor: ({ status, notes }) => (
      <div className='flex alignItemsCenter'>
        <Badge status={valueToBadgeStatus[status]} >
          {I18n.t(`enum.status.${status}`)}
        </Badge>
        {!!notes &&
          <div className='tooltip-height-20 ml8' style={{ height: 16 }}>
            <Tooltip content={notes}>
              <Icon source={NoteMajorMonotone} />
            </Tooltip>
          </div>
        }
      </div>
    )
  },
  {
    size: 2, header: "Acciones", accessor: (item, utils) => {
      if (!item.resolvable_by_user)
        return null;

      return (
        <div className="flex alignItemsCenter">
          <ButtonGroup>
            <Button size='slim' onClick={(event) => utils.handleAccept(event, item)} primary>{I18n.t("actions.accept")}</Button>
            <Button size='slim' onClick={(event) => utils.handleReject(event, item)} destructive >{I18n.t("actions.reject")}</Button>
          </ButtonGroup>
        </div>
      )
    }
  },
];

export const userColumns = [
  { size: 2, header: ta("general.name"), accessor: "name" },
  { size: 4, header: ta("user.email"), accessor: (user) => user.is_courier ? "" : user.email }, // hide email for couriers
  {
    size: 3, header: ta("user.role"),
    accessor: (user) => {
      if (user.is_courier) {
        return <RoleWithPosition color="warning" role={user.role_human} position="Mensajero" />
      }
      else if (user.is_team_leader) {
        return <RoleWithPosition color="info" role={user.role_human} position="Team Leader" />
      }
      else {
        return user.role_human
      }
    }
  },
  // { size: 2, header: ta("team.name"), accessor: (user) => user.team && user.team.name },
  {
    size: 2, header: I18n.t("activerecord.models.team.one"),
    accessor: (user) => {
      if (user.is_courier) {
        return user.courier.team_name
      }
      else if (user.is_team_leader) {
        return user.team.name
      }
      else {
        return ""
      }
    }
  }
];

export const dispatchControlColumns = [
  { size: 1, header: ta("dispatch_control.id"), accessor: "name" },
  { size: 3, header: I18n.t("activerecord.models.team.one"), accessor: (record) => record.team.name },
  { size: 3, header: ta("dispatch_control.created_at"), accessor: "created_at_text" },
  { size: 1, header: I18n.t("app.dashboard.orders_assigned_count"), accessor: (record) => (
    <span>
      { `${record.orders_assigned_count}/${record.items_count}` }
    </span>
  ) },
]

export const dashboardTeamsColumns = [
  { size: 3, header: I18n.t("activerecord.models.team.one"), accessor: "name" },
  { size: 2, header: I18n.t("app.dashboard.dispatch_control"), accessor: "dispatch_control" },
  { size: 2, header: I18n.t("app.dashboard.assigned_couriers"), accessor: "assigned_couriers" },
  { size: 2, header: I18n.t("app.dashboard.in_progress"), accessor: "in_progress" },
  { size: 2, header: I18n.t("app.dashboard.delivered"), accessor: "delivered" },
];

export const dashboardTaskColumns = [
  { size: 3, header: "Tipo", accessor: "kind" },
  { size: 2, header: "Creadas", accessor: "created_count" },
  { size: 2, header: "En Proceso", accessor: "in_progress" },
  { size: 2, header: "Completadas", accessor: "completed_count" }
];

export const messageTemplateColumns = [
  { size: 2, header: ta("general.title"), accessor: "title" },
  // { size: 2, header: ta("message_template.kind"), accessor: "kind" },
  { size: 2, header: ta("general.created_at"), accessor: (record) => I18n.l("time.formats.app_datetime", record.created_at) },
  { size: 2, header: ta("general.updated_at"), accessor: (record) => I18n.l("time.formats.app_datetime", record.updated_at) },
];

export const dashboardETAColumns = [
  { size: 4, header: "Días para Entrega", accessor: "days" },
  { size: 4, header: "Ordenes Entregadas", accessor: "orders_delivered" },
  { size: 4, header: "%", accessor: "percentage_orders" },
];

export const customerTicketColumns = [
  // Actions column
  { headers: "", accessor: (order, accessorUtils) => {
    const itemUrl = !!accessorUtils.baseUrl ? accessorUtils.baseUrl.replace(":id", parseInt(order.id) + 100) : null;
    return( // Extracted from old OrderItem component
      <div className='floating-hover-cell'>
        <ButtonGroup>
          { itemUrl && <Button size="slim" primary url={itemUrl}>Detalles</Button> }
        </ButtonGroup>
      </div>
    )
  }},
  // Data columns
  { header: ta('customer_ticket.ticket_kind'), accessor: ({ ticketKind }) => I18n.t(`enum.ticket_kind.${snakeCase(ticketKind)}`) },
  { header: ta("customer_ticket.name"), accessor: "name" },
  { header: ta('general.created_at'), accessor: ({ createdAt }) => I18n.l("time.formats.date_only", createdAt) },
  { header: ta('customer_ticket.status'), accessor: ({ status }) => (
    <Badge progress={valueToBadgeProgress[status]} status={valueToBadgeStatus[status]}>
      { I18n.t(`enum.status.${snakeCase(status)}`) }
    </Badge>
    
  )},
  { header: ta('customer_ticket.resolution_action'), accessor: ({ resolutionAction }) => (
    <div>
      {/* Only shows text when other than PENDING */}
      { (resolutionAction && resolutionAction != "PENDING") &&
        <span>
          {  I18n.t(`enum.resolution_action.${snakeCase(resolutionAction)}`) }
        </span>
      }
      {/* When PENDING exactly, display tooltip  */}
      { resolutionAction == "PENDING" &&
        <Tooltip content={I18n.t(`enum.resolution_action.pending`)}>
          <Icon color='yellow' source={RiskMajorMonotone} />
        </Tooltip>
      }
    </div>
  ) },
  { header: ta('customer_ticket.supplier_status'), accessor: ({ supplierStatus }) => supplierStatus && I18n.t(`enum.supplier_status.${snakeCase(supplierStatus)}`) },
  { header: ta('customer_ticket.provider_name'), accessor: ({ providerName }) => I18n.t(`enum.provider_name.${snakeCase(providerName)}`) },
  { header: ta('customer_ticket.customer_kind'), accessor: ({ customerKind }) => I18n.t(`enum.customer_kind.${customerKind.toLowerCase()}`) },
  { header: ta('customer_ticket.customer_email'), accessor: "customerEmail" },
  { header: ta('customer_ticket.customer_name'), accessor: "customerName" },
  { header: ta('customer_ticket.provider_order_id'), accessor: ({ displayProviderOrderId, providerOrderUrl }) => <Link url={providerOrderUrl} external>{ displayProviderOrderId}</Link> },
  { header: ta('customer_ticket.product_title'), accessor: "productTitle" },
  { header: ta('customer_ticket.product_sku'), accessor: "productSku" },
  { header: ta('customer_ticket.product_original_total'), accessor: "moneyProductOriginalTotal" },
  { header: ta('customer_ticket.reason'), accessor: ({ reason }) => I18n.t(`enum.reason.${snakeCase(reason)}`) },
  { header: ta('customer_ticket.resolution_preference'), accessor: ({ resolutionPreference }) => I18n.t(`enum.resolution_preference.${snakeCase(resolutionPreference)}`) },
  { header: ta('customer_ticket.retrieval_method'), accessor: ({ retrievalMethod }) => I18n.t(`enum.retrieval_method.${snakeCase(retrievalMethod)}`) },
  { header: ta('customer_ticket.require_items'), accessor: ({ requireItems, requireItemsDisplayable }) => requireItemsDisplayable && <PolarisBooleanBadge value={requireItems} /> },
  { header: ta('customer_ticket.items_received'), accessor: ({ itemsReceived }) => <PolarisBooleanBadge value={itemsReceived} /> },
];